import moment from 'moment';
import { ProcessInfo, InscriptionReplacement, ConsecutiveInscription, MultiLanguage, Criteria, ProcessConfigGroup } from '..';
import { ReplacementOriginType } from '@/Domain/enum/ReplacementOriginType';

export default class Replacement {
    private _id: string;
    private _name: string;
    private _creationDate: moment.Moment | null;
    private _validityDate: moment.Moment | null;
    private _process: ProcessInfo;
    private _inscriptionsReplacement: InscriptionReplacement[];
    private _selectedReplacementListCandidateIds: string[];
    private _selectedInscriptionsIds: string[] = [];
    private _group: ProcessConfigGroup | null;
    private _groupName: MultiLanguage;
    private _consecutiveInscriptions: ConsecutiveInscription[];
    private _isVisible: boolean;
    private _originProcess: MultiLanguage;
    private _isModify: boolean;
    private _criteria: Criteria;
    private _groupType: number | undefined;
    private _totalCandidates: number;
    private _totalAvailableCandidates: number;
    private _originType: number;
   
    public constructor(data: any, processConfigGroups?: ProcessConfigGroup[]) {
        this._id = data.id;
        this._name = data.name;
        this._creationDate = data.creationDate ? moment.utc(data.creationDate) : moment();
        this._validityDate = data.validityDate ? moment.utc(data.validityDate) : null;
        this._inscriptionsReplacement = data.inscriptionsReplacement ? data.inscriptionsReplacement : [];
        this._selectedReplacementListCandidateIds = data.selectedReplacementListCandidateIds || [];
        this._selectedInscriptionsIds = [];
        this._group = processConfigGroups ? processConfigGroups.filter(processConfigGroup => processConfigGroup.type === data.group)[0] : null;
        this._groupName = this._group ? this._group.title : new MultiLanguage('');
        this._groupType = this._group ? this._group.type : undefined;
        this._consecutiveInscriptions = [];
        this._isVisible = data.isVisible;
        this._isModify = data.isModify;
        this._criteria = data.criteria ? new Criteria(data.criteria) : new Criteria({});
        this._totalCandidates = data.totalCandidates;
        this._totalAvailableCandidates = data.totalAvailableCandidates;
        this._originType = data.originType;
        this._originProcess = data.originProcess ? new MultiLanguage(data.originProcess) : new MultiLanguage({});
        this._process = data.process ? new ProcessInfo(data.process, processConfigGroups) : new ProcessInfo({}, processConfigGroups);
    }

    public get id() {
        return this._id;
    }
    public set id(id: string) {
        this._id = id;
    }

    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }

    public get creationDate() {
        return this._creationDate;
    }
    public set creationDate(creationDate: moment.Moment | null) {
        this._creationDate = creationDate;
    }

    public get validityDate() {
        return this._validityDate;
    }
    public set validityDate(validityDate: moment.Moment | null) {
        this._validityDate = validityDate;
    }

    public get process() {
        return this._process;
    }
    public set process(process: ProcessInfo) {
        this._process = process;
    }

    public get inscriptionsReplacement() {
        return this._inscriptionsReplacement;
    }
    public set inscriptionsReplacement(inscriptionsReplacement: InscriptionReplacement[]) {
        this._inscriptionsReplacement = inscriptionsReplacement;
    }

    public get selectedReplacementListCandidateIds() {
        return this._selectedReplacementListCandidateIds;
    }
    public set selectedReplacementListCandidateIds(selectedReplacementListCandidateIds: string[]) {
        this._selectedReplacementListCandidateIds = selectedReplacementListCandidateIds;
    }

     public get selectedInscriptionsIds() {
        return this._selectedInscriptionsIds;
    }
    public set selectedInscriptionsIds(selectedInscriptionsIds: string[]) {
        this._selectedInscriptionsIds = selectedInscriptionsIds;
    }
    
    public get group() {
        return this._group;
    }
    public set group(group: ProcessConfigGroup | null) {
        this._group = group;
    }

    public get groupName() {
        return this._groupName;
    }

    public get consecutiveInscriptions() {
        return this._consecutiveInscriptions;
    }
    public set consecutiveInscriptions(consecutiveInscriptions: ConsecutiveInscription[]) {
        this._consecutiveInscriptions = consecutiveInscriptions;
    }

    public get isVisible() {
        return this._isVisible;
    }
    public set isVisible(isVisible: boolean) {
        this._isVisible = isVisible;
    }

    public get criteria() {
        return this._criteria;
    }
    public set criteria(criteria: Criteria) {
        this._criteria = criteria;
    }

    public get originProcess() {
        return this._originProcess;
    }

    public get isModify() {
        return this._isModify;
    }

    public get groupType() {
        return this._groupType;
    }

    public get totalCandidates(): number {
        return this._totalCandidates;
    }
    
    public set totalCandidates(value: number) {
        this._totalCandidates = value;
    }

    public get totalAvailableCandidates(): number {
        return this._totalAvailableCandidates;
    }
    public set totalAvailableCandidates(value: number) {
        this._totalAvailableCandidates = value;
    }

    public get originType(): number {
        return this._originType;
    }
    public set originType(originType: number) {
        this._originType = originType;
    }

    public addInscriptionId(inscriptionId: string) {
        this.selectedInscriptionsIds.push(inscriptionId);
    }

    public removeAllInscriptionsId() {
        this.selectedInscriptionsIds = [];
    }

    public removeInscriptionId(indexInscription: number) {
        this.selectedInscriptionsIds.splice(indexInscription, 1);
    }

    public emptyInscriptionsReplacement() {
        this.inscriptionsReplacement = [];
    }

    public getIndexInscription(inscriptionId: string): number {
        return this.selectedInscriptionsIds.indexOf(inscriptionId);
    }

    public getIsAllSelected(): boolean {
        return !this.inscriptionsReplacement.some(inscription => !inscription.isSelected);
    }

    public setIsAnySelectedInscription(indexTab: number) {
        const selectedInscriptions = this.inscriptionsReplacement.filter(inscription => inscription.isSelected);
        this.consecutiveInscriptions[indexTab].isAnySelected = selectedInscriptions.length > 0;
    }

    public getIsAllInscriptionsConsecutive(indexTab: number): boolean {
        this.setCurrentTabInscriptionsConsecutive(indexTab);
        return this.consecutiveInscriptions.every(inscription => inscription.isConsecutive);
    }

    public getIsAnySelectedAndIsNotFirstIndexSelected(): boolean {
        return this.consecutiveInscriptions.some(inscription => {
            return !inscription.isFirstIndexSelected && inscription.isAnySelected;
        });
    }
    
    public setInscriptionReplacementIsSelected(inscriptionReplacement: InscriptionReplacement) {
        const requestIndex = this.getIndexInscription(inscriptionReplacement.id);
        if (requestIndex >= 0) {
            inscriptionReplacement.isSelected = true;
        }
    }

    public setConsecutiveList(turnList: any[]) {
        turnList.forEach(() => {
            this.consecutiveInscriptions.push(new ConsecutiveInscription());
        });
    }

    public setCurrentTabInscriptionsConsecutive(indexTab: number) {
        const selectedInscriptions = this.inscriptionsReplacement.filter(inscription => inscription.isSelected);
        const isConsecutive = selectedInscriptions.every((current, index) => index === 0 || current.order === selectedInscriptions[index - 1].order + 1);
        const isFirtsIndexSelected = this.inscriptionsReplacement[0].isSelected;
        if (this.consecutiveInscriptions[indexTab]) {
            this.consecutiveInscriptions[indexTab].isConsecutive = isConsecutive;
            this.consecutiveInscriptions[indexTab].isFirstIndexSelected = isFirtsIndexSelected;
            this.setIsAnySelectedInscription(indexTab);
        }
    }

    public isAnyTurnCountEmpty(turnList: any[]): boolean {
        return turnList.every(turn => turn.count > 0);
    }

    public toServer() {
        return {
            id: this.id,
            name: this.name,
            creationDate: this.creationDate,
            validityDate: this.validityDate,
            processId: this.process ? this.process.id : null,
            inscriptionsReplacement: this.inscriptionsReplacement,
            selectedInscriptionsIds: this.selectedInscriptionsIds,
            criteria: this.criteria.toServer()
        };
    }
}
