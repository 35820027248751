export default class TextAreaContentHelperService {
    private _translatedContent: string;
    private _max: number | undefined;

    constructor(textAreaData: { translatedContent: string | undefined, max: number | string | undefined }) {
        this._translatedContent = textAreaData.translatedContent ? textAreaData.translatedContent : '';
        this._max = textAreaData.max ? Number(textAreaData.max) : undefined;
    }

    get content() {
        return this._translatedContent;
    }

    set content(value: string) {
        this._translatedContent = value;
    }

    get max() {
        return this._max;
    }

    set max(max: number | undefined) {
        this._max = max;
    }

    get contentTruncated() {
        return this._translatedContent.substring(0, this.max);
    }
    
    public isValidTranslatedContentLength() {
        return this.max !== undefined && this._translatedContent.length <= this.max;
    }
}
