import Enum from '../interfaces/IEnum';

export const InscriptionRegistry: Enum = {
    translations: {
        0: 'lang.shared.online', // applicant
        1: 'lang.shared.window', // admin
        2: 'lang.shared.onlineRepresentative'
    },
    enum: {
        ONLINE: 0,
        WINDOW: 1,
        ONLINE_REPRESENTATIVE: 2
    }
};
