import { Component, Vue } from 'vue-property-decorator';
import { Filter, EntityOep, SorterNew, ProcessInfo, FilterElement } from '@/Domain/Entities';
import { ProcessStatus, ProcessType, OppositionType, ProcessPhases, RatingsTabs } from '@/Domain/enum';
import { SdFilter, MdInput } from '@/Components';
import EntityService from '@/Services/EntityService';
import ProcessesService from '@/Services/ProcessesService';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

@Component({
    components: {
        SdFilter,
        MdInput
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('ratingsListStore', { filters: 'getFilters' }),
        orderedProcess() {
            return (this as RatingsList).sorter.sortBy((this as RatingsList).filter.filteredData);
        },
        adminBlockedProcessIds() {
            return this.$store.state.userStore.adminBlockedProcessIds || [];
        }
    },
    methods: {
        ...mapMutations('ratingsListStore', ['setFilters']),
        ...mapActions('ratingsListStore', ['setEntities'])
    },
    watch: {
        filter() {
            (this as RatingsList).setFilters((this as RatingsList).filter.filterElements);
        }
    }
})
export default class RatingsList extends Vue {
    currentLanguage!: string;
    filters!: FilterElement[];

    filter = new Filter();
    entityOepList: EntityOep[] = [];
    processList: ProcessInfo[] = [];
    filterDisplayed = false;
    ProcessStatus = ProcessStatus;
    ProcessType = ProcessType;
    OppositionType = OppositionType;
    ProcessPhases = ProcessPhases;
    RatingsTabs = RatingsTabs;
    loading = true;
    sorter = new SorterNew(['bopDate', 'title'], ['desc', 'asc']);

    setFilters!: (arg1: FilterElement[]) => void;
    setEntities!: (arg1: Array<{ id: string, name: string }>) => void;

    sortBy(keys: string[]) {
        this.sorter.updateKeys(keys);
    }

    getEntityList() {
        EntityService.getEntityList()
            .then(response => {
                this.entityOepList = response.map(elem => new EntityOep(elem));
                this.processList.forEach(process => process.setEntityName(this.entityOepList));
                this.setEntities(this.entityOepList);
                this.filter.setFilterElements(this.filters);
                this.filter.setData(this.processList);
                this.loading = false;
            }).catch(error => console.log(error));
    }

    getProcessListScale() {
        ProcessesService.getProcessListScale()
            .then(response => {
                this.processList = response.map(processInfo => new ProcessInfo(processInfo));
                this.getEntityList();
            }).catch(error => console.log(error));
    }

    goToRatings(process: ProcessInfo) {
        this.$router.push({
            name: 'RatingsEdit',
            params:
            {
                id: process.id,
                tabActive: process.oppositionType === OppositionType.enum.FREE_DESIGNATION ? RatingsTabs.enum.LISTS : this.getRatingsEditActiveTabIfNotFreeDesignation(process),
                hasRequirementWithTest: `${process.hasRequirementWithTest}`,
                fromPage: 'RatingsList'
            }
        });
    }

    getRatingsEditActiveTabIfNotFreeDesignation(process: ProcessInfo) {
       return process.oppositionType === OppositionType.enum.TENDER ? RatingsTabs.enum.MERTIS : RatingsTabs.enum.EXAMS;
    }

    mounted() {
        this.getProcessListScale();
    }
}
