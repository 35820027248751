import { ClientInfo } from '@/Domain/Entities';
import { ProcessConfigurationTypes } from '@/Domain/enum';
import IProcessConfig from '@/Domain/interfaces/IProcessConfig';
import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/masterData';

export default class MasterDataService {
    public static async getProvinces(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('provinces', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async getMunicipalities(provinceId: number): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('provinces/' + provinceId + '/municipalities', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async getStreetTypes(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('streetTypes', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async getCountries(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('countries', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async getClientInfo(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('headerConfiguration', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async getAutonomousCommunity(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('autonomousCommunities', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getNationalities(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('nationalities', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getClientLanguajes(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('languages', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getFeatureFlag(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('featureFlags', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessesConfiguration(type: number): Promise<any> {
        const endpoint = this.getProcessConfigEndpoint(type);
        return new Promise((resolve, reject) => {
            axios.get(`${endpoint}`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addProcessConfiguration(type: number, processConfig: IProcessConfig): Promise<any> {
        const endpoint = this.getProcessConfigEndpoint(type);
        return new Promise((resolve, reject) => {
            axios.post(`${endpoint}`, processConfig.toServer(), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateProcessConfig(type: number, processConfig: IProcessConfig): Promise<any> {
        const endpoint = this.getProcessConfigEndpoint(type);
        return new Promise((resolve, reject) => {
            axios.put(`${endpoint}`, processConfig.toServer(), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    // Async await refactor
    public static async getMunicipalitiesAsync(provinceId: number): Promise<any[]> {
        try {
            const response = await axios.get(`provinces/${provinceId}/municipalities`, { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getStreetTypesAsync(): Promise<any[]> {
        try {
            const response = await axios.get('streetTypes', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getCountriesAsync(): Promise<any[]> {
        try {
            const response = await axios.get('countries', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getClientInfoAsync(): Promise<ClientInfo> {
        try {
            const response = await axios.get('headerConfiguration', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getAutonomousCommunityAsync(): Promise<any[]> {
        try {
            const response = await axios.get('autonomousCommunities', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getNationalitiesAsync(): Promise<any[]> {
        try {
            const response = await axios.get('nationalities', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getProvincesAsync(): Promise<any[]> {
        try {
            const response = await axios.get('provinces', { baseURL });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public static async getProcessesConfigurationAsync(type: number): Promise<any> {
        const endpoint = this.getProcessConfigEndpoint(type);
        try {
          const response = await axios.get(`${endpoint}`, { baseURL });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    }
    
    private static _processConfigEndpoints = {
        [ProcessConfigurationTypes.enum.PAYEXEMPTIONS]: 'processConfigurationPayExemption',
        [ProcessConfigurationTypes.enum.AUTHORIZATIONS]: 'processConfigurationAuthorizationEntities',
        [ProcessConfigurationTypes.enum.EXAMS]: 'processConfigurationExam',
        [ProcessConfigurationTypes.enum.GROUPSUBGROUP]: 'processConfigurationGroup'
    };

    private static getProcessConfigEndpoint(processConfigType: number) {
        return this._processConfigEndpoints[processConfigType];
    }
}
