import { Component, Vue } from 'vue-property-decorator';
import { BOEP, Filter, FilterElement, SorterNew} from '@/Domain/Entities';
import _ from 'lodash';
import BOEPService from '@/Services/BOEPService';
import EntityService from '@/Services/EntityService';
import { SdFilter } from '@/Components';
import OepDocumentsService from '@/Services/OepDocumentsService';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';

@Component({
    components: {
        SdFilter
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('boepListStore', { filters: 'getFilters' }),
        orderedBOEP() {
            return (this as BOEPList).sorter.sortBy((this as BOEPList).filter.filteredData);
        }
    },
    methods: {
        ...mapMutations('boepListStore', ['setFilters']),
        ...mapActions('boepListStore', ['setEntities'])
    },
    watch: {
        filter() {
            (this as BOEPList).setFilters((this as BOEPList).filter.filterElements);
        }
    }
})
export default class BOEPList extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    filters!: FilterElement[];

    setFilters!: (arg1: FilterElement[]) => void;
    setEntities!: (arg1: Array<{id: string, name: string}>) => void;

    blocked = false;
    showModal = false;
    deleteId = '';
    allBOEP: any[] = [];
    entityList: any[] = [];
    filter = new Filter();
    sorter = new SorterNew(['year', 'title'], ['desc', 'asc']);
    loading: boolean = false;
    showFilter: boolean = true;
    span: any = null;
    $apm: any;

    sortBy(keys: string[]) {
        this.sorter.updateKeys(keys);
    }

    goToCreateBoep() {
        this.$router.push({
            name: 'BOEPNew'
        });
    }

    getEntityName(id: string): string {
        const filteredEntityList = this.entityList.filter(entity => entity.id === id);
        if (filteredEntityList.length) {
            return filteredEntityList[0].name;
        }
        return '';
    }

    deleteBOEP() {
        const self = this;
        self.showModal = false;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        BOEPService.removeBOEP(this.deleteId).then(() => {
            const BOEPIndex = self.allBOEP.indexOf(self.allBOEP.filter(elem => elem.id === self.deleteId)[0]);
            self.showFilter = false;
            self.allBOEP.splice(BOEPIndex, 1);
            self.filter.setData(self.allBOEP);
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.$nextTick(() => {
                self.showFilter = true;
            });
        }).catch(error => { throw new Error(error); });
    }

    getBOEPList() {
        BOEPService.getBOEPList().then( response => {
            this.allBOEP = response.map(boep => {
                const BOEPObject = new BOEP(boep);
                OepDocumentsService.getOepDocuments('boep/', boep.id).then(result => {
                    const orderedDocuments = (_ as any).orderBy(result, 'publicationDate', 'desc');
                    orderedDocuments.forEach((file: any) =>
                    BOEPObject.addDocument(file)
                    );
                }).catch(error => console.log(error));
                return BOEPObject;
            });
            this.getEntity();
        }).catch(() => this.loading = false);
    }

    closeModal() {
        this.showModal = false;
    }

    getEntity() {
        EntityService.getEntityList().then(response => {
            this.entityList = response;
            this.allBOEP.forEach(boep => boep.setEntityName(this.entityList));
            this.setEntities(this.entityList);
            this.filter.setFilterElements(this.filters);                
            this.filter.setData(this.allBOEP);
            this.loading = false;
        }).catch(() => this.loading = false);
    }

    created() {
        this.span = this.$apm.startSpan('component-BOEPList', 'custom');
        this.loading = true;
        this.getBOEPList();
    }

    mounted() {
        if (this.span) {
            this.span.end();
        } 
    }
}
