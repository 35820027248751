import { Component, Vue } from 'vue-property-decorator';
import { SdFilter } from '@/Components';
import { Filter, SorterNew, ShippingContact } from '@/Domain/Entities';
import filters from './ShippingHistoryReceivers.filter';
import ReplacementService from '@/Services/ReplacementService';
import { mapState } from 'vuex';

@Component({
    components: {
        SdFilter
    },
    computed: {
        filteredData() {
            return (this as ShippingHistoryReceivers).sorter.sortBy((this as ShippingHistoryReceivers).filter.filteredData);
        },
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'})
    }
})

export default class ShippingHistoryReceivers extends Vue {
    dateFormat!: string;
    
    filter = new Filter();
    loading: boolean = false;
    currentTabType = null as any;
    shippingHistoryName: string = '';
    replacementListId: string = '';
    shippingHistoryId: string = '';
    shippingHistoryReceivers: any = [];
    sorter = new SorterNew(['order'], ['asc', 'asc']);

    $ref!: {
        sdFilter: SdFilter
    };

    getShippingContactList() {
        ReplacementService.getShippingContactList(this.replacementListId, this.shippingHistoryId)
        .then(response => {
            this.shippingHistoryReceivers = response.map(receiver => new ShippingContact(receiver));
            this.filter.setData(this.shippingHistoryReceivers);
            this.filter.setFilterElements(filters);
            this.loading = false;
        }).catch(error => console.log(error));
    }

    initList() {
        this.loading = true;
        this.replacementListId = this.$route.params.id;
        this.shippingHistoryId = this.$route.params.shippingHistoryId;
        this.shippingHistoryName = this.$route.params.shippingHistoryName;
        this.currentTabType = this.$route.params.hash;
        this.getShippingContactList();
    }

    goToReplacementInfo(receiver: ShippingContact) {
        this.$router.push({ name: 'ReplacementStatusContract', params: { id: receiver.id, replacementListCandidateId: receiver.replacementListCandidateId, hash: '0', fromPage: 'ShippingHistoryReceivers', fromShippingHistory: JSON.stringify(this.$route.params)  } });
    }
    
    mounted() {
        this.initList();
    }
}
