export enum TurnsTypesTranslations {
    FREE = 'lang.listManagement.freeTurnEncoding',
    FREE_DISABILITY = 'lang.listManagement.freeDisabilityTurnEncoding',
    INTERNAL = 'lang.listManagement.internalTurnEncoding',
    INTERNAL_DISABILITY = 'lang.listManagement.internalDisabilityTurnEncoding',
    MOBILITY = 'lang.listManagement.mobilityTurnEncoding'
}

export enum CountryTypesTranslations {
    AFGANA = '004 \'Afgana\'',
    ALBANESA = '008 \'Albanesa\'',
    ALEMANA = '276 \'Alemana\'',
    ANDORRANA = '020 \'Andorrana\'',
    ANGOLENA = '024 \'Angoleña\'',
    ANTIGUANA = '028 \'Antiguana\'',
    ARABE = '682 \'Árabe\'',
    ARGELINA = '012 \'Argelina\'',
    ARGENTINA = '032 \'Argentina\'',
    ARMENIA = '051 \'Armenia\'',
    AUSTRALIANA = '036 \'Australiana\'',
    AUSTRIACA = '040 \'Austriaca\'',
    AZERBAIYANA = '031 \'Azerbaiyana\'',
    BAHAMEÑA = '044 \'Bahameña\'',
    BANGLADES = '050 \'Bangladés\'',
    BARBADOS = '052 \'Barbados\'',
    BAREIN = '048 \'Baréin\'',
    BELGA = '056 \'Belga\'',
    BELICEÑA = '084 \'Beliceña\'',
    BENINESA = '204 \'Beninesa\'',
    BIELORRUSA = '112 \'Bielorrusa\'',
    BIRMANA = '104 \'Birmana\'',
    BOLIVIANA = '068 \'Boliviana\'',
    BOSNIA = '070 \'Bosnia\'',
    BOTSWANESA = '072 \'Botsuanesa\'',
    BRASILEÑA = '076 \'Brasileña\'',
    BRUNEI = '096 \'Brunéi\'',
    BULGARA = '100 \'Búlgara\'',
    BURKINESA = '854 \'Burkinesa\'',
    BURUNDESA = '108 \'Burundesa\'',
    BUTANESA = '064 \'Butanesa\'',
    CABOVERDIANA = '132 \'Caboverdiana\'',
    CAMBOYANA = '116 \'Camboyana\'',
    CAMERUNESA = '120 \'Camerunesa\'',
    CANADIENSE = '124 \'Canadiense\'',
    CATAR = '634 \'Catar\'',
    CHADIANA = '148 \'Chadiana\'',
    CHILENA = '152 \'Chilena\'',
    CHINA = '156 \'China\'',
    CHIPRIOTA = '196 \'Chipriota\'',
    VATICANA = '336 \'Vaticana\'',
    COLOMBIANA = '170 \'Colombiana\'',
    COMORENSA = '174 \'Comorensa\'',
    NORCOREANA = '408 \'Norcoreana\'',
    SURCOREANA = '410 \'Surcoreana\'',
    MARFILENA = '384 \'Marfileña\'',
    COSTARRICENSE = '188 \'Costarricense\'',
    CROATA = '191 \'Croata\'',
    CUBANA = '192 \'Cubana\'',
    DANESA = '208 \'Danesa\'',
    DOMINIQUESA = '212 \'Dominiquesa\'',
    ECUATORIANA = '218 \'Ecuatoriana\'',
    EGIPCIA = '818 \'Egipcia\'',
    SALVADORENA = '222 \'Salvadoreña\'',
    EMIRATI = '784 \'Emiratí\'',
    ERITREA = '232 \'Eritrea\'',
    ESLOVACA = '703 \'Eslovaca\'',
    ESLOVENA = '705 \'Eslovena\'',
    ESPANOLA = '724 \'Española\'',
    ESTADOUNIDENSE = '840 \'Estadounidense\'',
    ESTONIANA = '233 \'Estoniana\'',
    ETIOPE = '231 \'Etiope\'',
    FILIPINA = '608 \'Filipina\'',
    FINLANDESA = '246 \'Finlandesa\'',
    FIYIANA = '242 \'Fiyiana\'',
    FRANCESA = '250 \'Francesa\'',
    GABONESA = '266 \'Gabonesa\'',
    GAMBIANA = '270 \'Gambiana\'',
    GEORGIANA = '268 \'Georgiana\'',
    GHANESA = '288 \'Ghanesa\'',
    GRANADINA = '308 \'Granadina\'',
    GRIEGA = '300 \'Griega\'',
    GUATEMALTECA = '320 \'Guatemalteca\'',
    GUYANESA = '328 \'Guyanesa\'',
    GUINEANA = '324 \'Guineana\'',
    ECUATOGUINEANA = '226 \'Ecuatoguineana\'',
    BISAUGUINEANA = '624 \'Bisauguineana\'',
    HAITIANA = '332 \'Haitiana\'',
    HONDURENA = '340 \'Hondureña\'',
    HUNGARA = '348 \'Húngara\'',
    INDIA = '356 \'India\'',
    INDONESA = '360 \'Indonesa\'',
    IRAQUI = '368 \'Iraquí\'',
    IRANI = '364 \'Iraní\'',
    IRLANDESA = '372 \'Irlandesa\'',
    ISLANDESA = '352 \'Islandesa\'',
    MARSHALESA = '584 \'Marshalesa\'',
    ISLASSALOMON = '090 \'Islas Salomón\'',
    ISRAELI = '376 \'Israelí\'',
    ITALIANA = '380 \'Italiana\'',
    JAMAICANA = '388 \'Jamaicana\'',
    JAPONESA = '392 \'Japonesa\'',
    JORDANA = '400 \'Jordana\'',
    KAZAJA = '398 \'Kazaja\'',
    KENIANA = '404 \'Keniana\'',
    KIRGUISTAN = '417 \'Kirguistán\'',
    KIRIBATIANA = '296 \'Kiribatiana\'',
    KUWAIT = '414 \'Kuwait\'',
    LAOSIANA = '418 \'Laosiana\'',
    LESOTO = '426 \'Lesoto\'',
    LETONA = '428 \'Letona\'',
    LIBANESA = '422 \'Libanesa\'',
    LIBERIANA = '430 \'Liberiana\'',
    LIBIA = '434 \'Libia\'',
    LIECHTENSTEINIANA = '438 \'Liechtensteiniana\'',
    LITUANA = '440 \'Lituana\'',
    LUXEMBURGUESA = '442 \'Luxemburguesa\'',
    MACEDONIA = '807 \'Macedonia\'',
    MADAGASCAR = '450 \'Madagascar\'',
    MALAYA = '458 \'Malaya\'',
    MALAWI = '454 \'Malawi\'',
    MALDIVA = '462 \'Maldiva\'',
    MALI = '466 \'Malí\'',
    MALTESA = '470 \'Maltesa\'',
    MARROQUI = '504 \'Marroquí\'',
    MAURICIANA = '480 \'Mauriciana\'',
    MAURITANA = '478 \'Mauritana\'',
    MEXICANA = '484 \'Méxicana\'',
    MICRONESIA = '583 \'Micronesia\'',
    MOLDAVA = '498 \'Moldava\'',
    MONEGASCA = '492 \'Monegasca\'',
    MONGOLA = '496 \'Mongola\'',
    MONTENEGRINA = '499 \'Montenegrina\'',
    MOZAMBIQUENA = '508 \'Mozambiqueña\'',
    NAMIBIA = '516 \'Namibia\'',
    NAURU = '520 \'Nauru\'',
    NEPALI = '524 \'Nepalí\'',
    NICARAGÜENSE = '558 \'Nicaragüense\'',
    NIGERINA = '562 \'Nigerina\'',
    NIGERIANA = '566 \'Nigeriana\'',
    NORUEGA = '578 \'Noruega\'',
    NEOZELANDESA = '554 \'Neozelandesa\'',
    OMANI = '512 \'Omaní\'',
    NEERLANDESA = '528 \'Neerlandesa\'',
    PAKISTAN = '586 \'Pakistán\'',
    PALAUANA = '585 \'Palauana\'',
    PANAMEÑA = '591 \'Panameña\'',
    PAPUANA = '598 \'Papuana\'',
    PARAGUAYA = '600 \'Paraguaya\'',
    PERUANA = '604 \'Peruana\'',
    POLACA = '616 \'Polaca\'',
    PORTUGUESA = '620 \'Portuguesa\'',
    BRITANICA = '826 \'Británica\'',
    CENTROAFRICANA = '140 \'Centroafricana\'',
    CHECA = '203 \'Checa\'',
    REPUBLICACONGO = '178 \'República del Congo\'',
    REPUBLICADEMOCRATICACONGO = '180 \'República Democrática del Congo\'',
    DOMINICANA = '214 \'Dominicana\'',
    RUANDESA = '646 \'Ruandesa\'',
    RUMANA = '642 \'Rumana\'',
    RUSA = '643 \'Rusa\'',
    SAMOANA = '882 \'Samoana\'',
    SANCAROLINA = '659 \'Sancristobaleña\'',
    SANMARINO = '674 \'San Marino\'',
    SANVICENTINA = '670 \'Sanvicentina\'',
    SANTALUCIA = '662 \'Santa Lucía\'',
    SANTOTOMENSE = '678 \'Santo Tomé y Príncipe\'',
    SENEGALSA = '686 \'Senegalesa\'',
    SERBIA = '891 \'Serbia\'',
    SEYCHELLENSE = '690 \'Seychelles\'',
    SIERRALEONESA = '694 \'Sierraleonesa\'',
    SINGAPURENSE = '702 \'Singapur\'',
    SIRIA = '760 \'Siria\'',
    SOMALI = '706 \'Somalia\'',
    SRILANQUES = '144 \'Sri Lanka\'',
    SUASILANDESA = '748 \'Suazilandia\'',
    SUDAFRICANA = '710 \'Sudafricana\'',
    SUDANES = '729 \'Sudanesa\'',
    SURSUDANES = '728 \'Sursudanesa\'',
    SUECA = '752 \'Sueca\'',
    SUIZA = '756 \'Suiza\'',
    SURINAMES = '740 \'Surinamesa\'',
    TAILANDES = '764 \'Tailandesa\'',
    TANZANA = '834 \'Tanzana\'',
    TAYIKO = '762 \'Tayikistán\'',
    TIMORES = '626 \'Timor Oriental\'',
    TOGUENSE = '768 \'Togolesa\'',
    TONGANA = '776 \'Tongana\'',
    TRINITENSE = '780 \'Trinidad y Tobago\'',
    TUNECINA = '788 \'Tunecina\'',
    TURCOMANA = '795 \'Turcomana\'',
    TURCA = '792 \'Turca\'',
    TUVALUANA = '798 \'Tuvalu\'',
    UCRANIANA = '804 \'Ucraniana\'',
    UGANDESA = '800 \'Ugandesa\'',
    URUGUAYA = '858 \'Uruguaya\'',
    UZBEKA = '860 \'Uzbeka\'',
    VANUATUENSE = '548 \'Vanuatu\'',
    VENEZOLANA = '862 \'Venezolana\'',
    VIETNAMITA = '868 \'Vietnamita\'',
    YEMENI = '887 \'Yemenita\'',
    YIBUTIANA = '262 \'Yibutiana\'',
    ZAMBIANA = '894 \'Zambiana\'',
    ZIMBABUENSE = '716 \'Zimbabue\''
}

export enum ProvincesTypesTranslations {
    ALBACETE = '2, \'Albacete\'',
    ALICANTE = '3, \'Alicante/Alacant\'',
    ALMERIA = '4, \'Almería\'',
    ALAVA = '1, \'Araba/Álava\'',
    ASTURIAS = '33, \'Asturias\'',
    AVILA = '5, \'Ávila\'',
    BADAJOZ = '6, \'Badajoz\'',
    BALEARES = '7, \'Balears, Illes\'',
    BARCELONA = '8, \'Barcelona\'',
    BIZKAIA = '48, \'Bizkaia\'',
    BURGOS = '9, \'Burgos\'',
    CACERES = '10, \'Cáceres\'',
    CADIZ = '11, \'Cádiz\'',
    CANTABRIA = '39, \'Cantabria\'',
    CASTELLON = '12, \'Castellón/Castelló\'',
    CIUDADREAL = '13, \'Ciudad Real\'',
    CORDOBA = '14, \'Córdoba\'',
    ACORUNA = '15, \'Coruña, A\'',
    CUENCA = '16, \'Cuenca\'',
    GIPUZKOA = '20, \'Gipuzkoa\'',
    GIRONA = '17, \'Girona\'',
    GRANADA = '18, \'Granada\'',
    GUADALAJARA = '19, \'Guadalajara\'',
    HUELVA = '21, \'Huelva\'',
    HUESCA = '22, \'Huesca\'',
    JAEN = '23, \'Jaén\'',
    LEON = '24, \'León\'',
    LLEIDA = '25, \'Lleida\'',
    LUGO = '27, \'Lugo\'',
    MADRID = '28, \'Madrid\'',
    MALAGA = '29, \'Málaga\'',
    MURCIA = '30, \'Murcia\'',
    NAVARRA = '31, \'Navarra\'',
    OURENSE = '32, \'Ourense\'',
    PALENCIA = '34, \'Palencia\'',
    LASPALMAS = '35, \'Palmas, Las\'',
    PONTEVEDRA = '36, \'Pontevedra\'',
    LARIOJA = '26, \'Rioja, La\'',
    SALAMANCA = '37, \'Salamanca\'',
    TENERIFE = '38, \'Santa Cruz de Tenerife\'',
    SEGOVIA = '40, \'Segovia\'',
    SEVILLA = '41, \'Sevilla\'',
    SORIA = '42, \'Soria\'',
    TARRAGONA = '43, \'Tarragona\'',
    TERUEL = '44, \'Teruel\'',
    TOLEDO = '45, \'Toledo\'',
    VALENCIA = '46, \'Valencia/València\'',
    VALLADOLID = '47, \'Valladolid\'',
    ZAMORA = '49, \'Zamora\'',
    ZARAGOZA = '50, \'Zaragoza\'',
    CEUTA = '51,\'Ceuta\'',
    MELILLA = '52, \'Melilla\''
}

export enum StreetTypesTranslations {
    ALAMEDA = 'lang.listManagement.groveEncoding',
    APARTAMENTO = 'lang.listManagement.apartmentEncoding',
    AVENIDA = 'lang.listManagement.avenueEncoding',
    BLOQUE = 'lang.listManagement.blockEncoding',
    BARRIO = 'lang.listManagement.neighborhoodEncoding',
    CHALET = 'lang.listManagement.chaletEncoding',
    CALLE = 'lang.listManagement.streetEncoding',
    CAMINO = 'lang.listManagement.pathEncoding',
    COLONIA = 'lang.listManagement.suburbEncoding',
    CARRETERA = 'lang.listManagement.roadEncoding',
    CASERIO = 'lang.listManagement.hamletEncoding',
    CUESTA = 'lang.listManagement.hillEncoding',
    CUENCA = 'lang.listManagement.watershedEncoding',
    EDIFICIO = 'lang.listManagement.buildingEncoding',
    GLORIETA = 'lang.listManagement.roundaboutEncoding',
    GRUPO = 'lang.listManagement.groupEncoding',
    LUGAR = 'lang.listManagement.siteEncoding',
    MERCADO = 'lang.listManagement.marketEncoding',
    MANZANA = 'lang.listManagement.squareEncoding',
    POBLADO = 'lang.listManagement.populatedEncoding',
    POLIGONO = 'lang.listManagement.polygonEncoding',
    PASAJE = 'lang.listManagement.passageEncoding',
    PARQUE = 'lang.listManagement.parkEncoding',
    PROLONGACION = 'lang.listManagement.extensionEncoding',
    PASEO = 'lang.listManagement.rideEncoding',
    PLAZA = 'lang.listManagement.placeEncoding',
    RAMBLA = 'lang.listManagement.ramblaEncoding',
    RONDA = 'lang.listManagement.roundEncoding',
    TRAVESIA = 'lang.listManagement.crossingEncoding',
    URBANIZACION = 'lang.listManagement.urbanizationEncoding'
}

export enum CandidateStatusTranslations {
    EXCLUDED = 'lang.replacement.excludedEncoding',
    RESIGNATION = 'lang.replacement.resignationEncoding',
    NOT_AVAILABLE = 'lang.replacement.notAvailableEncoding',
    WORKING = 'lang.replacement.workingEncoding',
    AVAILABLE = 'lang.replacement.availableEncoding',
    UNTRACEABLE = 'lang.replacement.untraceableEncoding'
}
