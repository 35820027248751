import { Merit, MultiLanguage } from '@/Domain/Entities';
import { BaseMeritsType } from '../../enum';
import MeritSubGroup from './MeritSubGroup';

export default class MeritGroup {
    private _id: string;
    private _groupType: number;
    private _description: MultiLanguage;
    private _maxScore: number;
    private _order: number;
    private _meritChilds: Array<MeritSubGroup | Merit>;
    private _isEditingMerit: boolean;
    private _isCreatingMerit: boolean;
    private _isEditingMeritSubGroup: boolean;
    private _isCreatingMeritSubGroup: boolean;
    private _isEditing: boolean;
    private _isDropped: boolean;
    private _editingMeritSubGroup: MeritSubGroup | null;
    private _merits: Merit[];
    private _meritSubGroups: MeritSubGroup[];

    public constructor(data: any) {
        this._id = data.id;
        this._groupType = data.groupName != null ? data.groupName : data.groupType;
        this._description = new MultiLanguage(data.description);
        this._maxScore = data.maxScore;
        this._order = data.order;
        this._meritChilds = data.meritChilds ? data.meritChilds.map(baseMerit => baseMerit.type === BaseMeritsType.enum.MERIT ? new Merit(baseMerit, data.id) : new MeritSubGroup(baseMerit)) : [];
        this._isEditingMerit = false;
        this._isCreatingMerit = false;
        this._isEditingMeritSubGroup = false;
        this._isCreatingMeritSubGroup = false;
        this._isEditing = false;
        this._isDropped = false;
        this._editingMeritSubGroup = null;
        this._merits = data.merits ? data.merits.map(merit => new Merit(merit, data.id)) : [];
        this._meritSubGroups = data.meritSubGroups ? data.meritSubGroups.map(subGroup => new MeritSubGroup(subGroup)) : [];
    }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get groupType() {
        return this._groupType;
    }

    public set groupType(groupType: number) {
        this._groupType = groupType;
    }

    public get description() {
        return this._description;
    }

    public set description(description: MultiLanguage) {
        this._description = description;
    }
    public get maxScore() {
        return this._maxScore;
    }

    public set maxScore(maxScore: number) {
        this._maxScore = maxScore;
    }
    public get order() {
        return this._order;
    }

    public set order(order: number) {
        this._order = order;
    }

    public get meritChilds() {
        return this._meritChilds;
    }

    public set meritChilds(meritChilds: Array<MeritSubGroup | Merit>) {
        this._meritChilds = meritChilds;
    }

    public get isEditingMerit() {
        return this._isEditingMerit;
    }

    public set isEditingMerit(isEditingMerit: boolean) {
        this._isEditingMerit = isEditingMerit;
    }

    public get isCreatingMerit() {
        return this._isCreatingMerit;
    }

    public set isCreatingMerit(isCreatingMerit: boolean) {
        this._isCreatingMerit = isCreatingMerit;
    }

    public get isEditingMeritSubGroup() {
        return this._isEditingMeritSubGroup;
    }

    public set isEditingMeritSubGroup(isEditingMeritSubGroup: boolean) {
        this._isEditingMeritSubGroup = isEditingMeritSubGroup;
    }

    public get isCreatingMeritSubGroup() {
        return this._isCreatingMeritSubGroup;
    }

    public set isCreatingMeritSubGroup(isCreatingMeritSubGroup: boolean) {
        this._isCreatingMeritSubGroup = isCreatingMeritSubGroup;
    }

    public get isEditing() {
        return this._isEditing;
    }
    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }

    public get isDropped() {
        return this._isDropped;
    }
    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }

    public get editingMeritSubGroup() {
        return this._editingMeritSubGroup;
    }

    public set editingMeritSubGroup(editingMeritSubGroup: MeritSubGroup | null) {
        this._editingMeritSubGroup = editingMeritSubGroup;
    }

    public get merits() {
        return this._merits;
    }

    public get numMerits() {
        const sumSubMerits = (this._meritSubGroups || []).reduce((acc, sub) => {
          return acc + (sub.merits ? sub.merits.length : 0);
        }, 0);
        return sumSubMerits + (this._merits ? this._merits.length : 0);
    }

    public get meritSubGroups() {
        return this._meritSubGroups;
    }

    public set meritSubGroups(meritSubGroups: MeritSubGroup[]) {
        this._meritSubGroups = meritSubGroups;
    }

    public findMerit(meritId, meritSubGroupId?) {
        if (meritSubGroupId) {
            const subGroup = this.meritChilds.find(children => children.id === meritSubGroupId);
            return subGroup ? (subGroup as MeritSubGroup).merits.find(element => element.id === meritId) : null;
        } else {
            return this.meritChilds.find(element => element.id === meritId);
        }
    }

    public setPropsAddMerit(objectToCreate) {
        objectToCreate.isCreatingMerit = true;
        this.isEditingMerit = false;
        this.isEditingMeritSubGroup = false;
    }

    public setPropsAddMeritSubGroup(value) {
        value.isEditingMeritGroup = false;
        this.isCreatingMeritSubGroup = true;
        this.isEditingMeritSubGroup = false;
        this.isCreatingMerit = false;
        this.isEditingMerit = false;
    }

    public setPropsEditMerit(merit: Merit) {
        this.isCreatingMerit = false;
        this.isEditingMerit = true;
        this.isCreatingMeritSubGroup = false;
        this.isEditingMeritSubGroup = false;
        merit.isEditing = true;
    }

    public totalGroupScore(): number {
        let totalGroupScore = 0;
        this._meritChilds.forEach(meritChild => {
            let totalSubGroupScore = 0;
            if (meritChild.type === BaseMeritsType.enum.MERIT && meritChild.userTotalScore) {
                totalGroupScore = totalGroupScore + meritChild.userTotalScore;
            }
            if (meritChild.type === BaseMeritsType.enum.MERITSUBGROUP) {
                (meritChild as MeritSubGroup).merits.forEach(merit => {
                    if (merit.userTotalScore) {
                        totalSubGroupScore = totalSubGroupScore + merit.userTotalScore;
                    }
                });
                if (totalSubGroupScore > meritChild.maxScore) {
                    totalSubGroupScore = meritChild.maxScore;
                }
            }
            totalGroupScore = totalGroupScore + totalSubGroupScore;
        });
        if (this._maxScore && totalGroupScore > this._maxScore) {
            totalGroupScore = this._maxScore;
        }
        return Number(totalGroupScore.toFixed(6));
    }

    public setPropsEditMeritSubGroups(value) {
        this.isCreatingMeritSubGroup = false;
        this.isEditingMerit = false;
        this.isEditingMeritSubGroup = true;
        if (this.editingMeritSubGroup) {
            value.editingMeritGroup.editingMeritSubGroup.isCreatingMerit = false;
            this.editingMeritSubGroup.isEditing = true;
            value.editingMeritGroup.isEditingMerit = false;
        }
    }

    public infoToServer() {
        return {
            id: this.id,
            groupName: this.groupType,
            description: this.description,
            maxScore: this.maxScore,
            order: this.order
        };

    }

    public setPropsAfterEdit(meritGroupToEdit) {
        this.groupType = meritGroupToEdit.groupName != null ? meritGroupToEdit.groupName : meritGroupToEdit.groupType;
        this.description = new MultiLanguage(meritGroupToEdit.description);
        this.maxScore = meritGroupToEdit.maxScore;
    }

    public toServerToInscription() {
        const meritToServe = [] as any;

        this.meritChilds.forEach(children => {
            if (children.type === BaseMeritsType.enum.MERIT) {
                meritToServe.push(children.toServerToInscription());
            }
            if (children.type === BaseMeritsType.enum.MERITSUBGROUP) {
                (children as MeritSubGroup).merits.forEach(merit => {
                    merit.meritGroupId = this._id;
                    meritToServe.push(merit.toServerToInscription());
                });
            }
        });
        return {
            id: this._id,
            merits: meritToServe
        };
    }

    getAllMerits() {
        const allMerits: Merit[] = [];
        
        this.meritChilds.forEach(children => {
            if (children instanceof Merit) {
                allMerits.push(children);
            }
            if (children instanceof MeritSubGroup) {
                children.merits.forEach(merit => {
                    allMerits.push((merit as Merit));
                });
            }
        });

        return allMerits;
    }
}
