import { Component, Vue } from 'vue-property-decorator';
import { SdSlideOver } from '..';
import { Replacement } from '@/Domain/Entities';
import moment from 'moment';
import { CountryTypesTranslations, ProvincesTypesTranslations, ReplacementReasons, StatusReplacement, StreetTypesTranslations, TurnsTypesTranslations } from '@/Domain/enum';
import ReplacementService from '@/Services/ReplacementService';
import { ToasterService } from '@/Services/ToasterService';
import CsvService from '@/Services/CsvService';
import i18n from '../../lang';
import { CandidateStatusTranslations } from '@/Domain/enum/FieldEncodingTypes';

@Component({
  computed: {
    isImportDisabled() {      
      return !(this as ImportReplacementList).replacementList.name 
        || !moment.isMoment((this as ImportReplacementList).replacementList.creationDate) 
        || (this as ImportReplacementList).file === null;
    }
  }
})

export default class ImportReplacementList extends Vue {
  currentLanguage!: string;

  loading: boolean = false;
  showModalFieldEncoding: boolean = false;
  csvToUpload: any[] = [];
  file: any = null;    
  showModalError: boolean = false;
  replacementList: Replacement = new Replacement({});
  moment = moment;
  submitted: boolean = false;
  TurnsTypesTranslations = TurnsTypesTranslations;
  CandidateStatusTranslations = CandidateStatusTranslations;
  CountryTypesTranslations = CountryTypesTranslations;
  ProvincesTypesTranslations = ProvincesTypesTranslations;
  StreetTypesTranslations = StreetTypesTranslations;
  ReplacementReasons = ReplacementReasons;
  StatusReplacement = StatusReplacement;
  replacementListSuccess: null | number = null;
  replacementListError: null | number = null;
  urlDownloadCsv: string = '';

  $refs!: {
    sdSlideOver: SdSlideOver;
  };

  closeSlideOver() {
    this.$emit('closeSlideOver');
    this.$refs.sdSlideOver.setOverflowAutoToBody();
  }

  closeModalError() {
    this.showModalError = false;
  }

  getImportReplacementListCsvTemplate() {
    ReplacementService.getImportReplacementListCsvTemplate();
  }

  showFieldEncoding() {
    this.showModalFieldEncoding = true;
  }

  removeFile() {
    this.file = null;
    this.csvToUpload = [];
  }

  addFile(file: any) {
    file.fileName = file.name;
    file.allowRemove = true;
    this.csvToUpload.push(file);
    this.file = file;
  }

  importCSVLoadReplacementList() {
    this.submitted = true;
    if (!this.file || !this.isReplacementListValidForImport()) {
        return;
    }
    ReplacementService.importCSVLoadReplacementList(this.createFormData()).then((response: {errorCsv: string, errorCount: number}) => {
        this.submitted = false;
        if (response.errorCsv) {
            this.replacementListError = response.errorCount;
            this.urlDownloadCsv = response.errorCsv; 
            this.showModalError = true;
        } else {
            ToasterService.showSuccess(i18n.t('lang.toaster.successReplacementListLoad') as string);
            this.$emit('reloadReplacementList');
            this.closeSlideOver();
        }
    });
  }

  createFormData(): FormData {
    const formData = new FormData();
    formData.append('replacementListImportedCsv', this.file);
    formData.append('name', this.replacementList.name);
    if (this.replacementList.creationDate) {
      formData.append('creationDate', this.replacementList.creationDate.toISOString());
    }
    if (this.replacementList.validityDate) {
      formData.append('validityDate', this.replacementList.validityDate.toISOString());
    }
    return formData;
  }

  downloadCsvErrors() {
    CsvService.getCsvFromBase64(this.urlDownloadCsv, i18n.t('lang.shared.replacementListImportedWithErrors') as string);
  }
  
  closeModalFieldEncoding() {
    this.showModalFieldEncoding = false;
  }

  isReplacementListValidForImport() {
    const validRequiredFields = this.replacementList.name !== null && moment.isMoment(this.replacementList.creationDate);
    return this.replacementList.validityDate === null ? validRequiredFields 
    : validRequiredFields && moment(this.replacementList.validityDate).isAfter(this.replacementList.creationDate);
  }

}
