
import { RankedInscrptionStatus, ReasonChange } from '@/Domain/enum';
import { Inscription } from '@/Domain/Entities';

export default class InscriptionRanked extends Inscription {
    private _status: number;
    private _gradeScore: number;
    private _scaleScore: number;
    private _totalScore: number;
    private _showedTooltip: boolean;
    private _phoneNumber1: number;
    private _phoneNumber2: number;
    private _email: string;
    private _hasVacant: boolean | null;
    private _reasonExcluded: number;
    private _annotations: string;
    private _tieType: number;
    private _gender: number;
    private _age: number;
    private _turn: number;
    private _fullNameToShow: string;
    private _nifToShow: string;

    public constructor(data: any) {
        super(data);
        this._gradeScore = data.gradeScore;
        this._scaleScore = data.scaleScore;
        this._totalScore = Number(data.totalScore.toString().match(/^-?\d+(?:\.\d{0,6})?/));
        this._status = data.status;
        this._showedTooltip = false;
        this._phoneNumber1 = data.phoneNumber1;
        this._phoneNumber2 = data.phoneNumber2;
        this._email = data.email;
        this._hasVacant = data.hasVacant;
        this._reasonExcluded = data.reasonExcluded;
        this._annotations = data.annotations || '';
        this._tieType = data.tieType;
        this._gender = data.gender;
        this._age = data.age;
        this._turn = data.turn;
        this._fullNameToShow = data.fullName;
        this._nifToShow = data.nif;
        this.statusToFront();
    }

    public get gradeScore() {
        return this._gradeScore;
    }

    public set gradeScore(gradeScore: number) {
        this._gradeScore = gradeScore;
    }

    public get scaleScore() {
        return this._scaleScore;
    }

    public set scaleScore(scaleScore: number) {
        this._scaleScore = scaleScore;
    }

    public get totalScore() {
        return this._totalScore;
    }

    public set totalScore(totalScore: number) {
        this._totalScore = totalScore;
    }

    public get status() {
        return this._status;
    }

    public set status(status: number) {
        this._status = status;
    }

    public get showedTooltip() {
        return this._showedTooltip;
    }

    public set showedTooltip(showedTooltip: boolean) {
        this._showedTooltip = showedTooltip;
    }

    public get phoneNumber1() {
        return this._phoneNumber1;
    }
    public set phoneNumber1(phoneNumber1: number) {
        this._phoneNumber1 = phoneNumber1;
    }

    public get phoneNumber2() {
        return this._phoneNumber2;
    }
    public set phoneNumber2(phoneNumber2: number) {
        this._phoneNumber2 = phoneNumber2;
    }

    public get email() {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }

    public get hasVacant() {
        return this._hasVacant;
    }
    public set hasVacant(hasVacant: boolean | null) {
        this._hasVacant = hasVacant;
    }

    public get reasonExcluded() {
        return this._reasonExcluded;
    }
    public set reasonExcluded(reasonExcluded: number) {
        this._reasonExcluded = reasonExcluded;
    }

    public get annotations() {
        return this._annotations;
    }
    public set annotations(annotations: string) {
        this._annotations = annotations;
    }

    public get tieType() {
        return this._tieType;
    }

    public set tieType(tieType: number) {
        this._tieType = tieType;
    }

    public get gender() {
        return this._gender;
    }

    public set gender(gender: number) {
        this._gender = gender;
    }

    public get age() {
        return this._age;
    }

    public set age(age: number) {
        this._age = age;
    }

    public get turn() {
        return this._turn;
    }

    public get fullNameToShow() {
        return this._fullNameToShow;
    }

    public set fullNameToShow(fullNameToShow: string) {
        this._fullNameToShow = fullNameToShow;
    }

    public get nifToShow() {
        return this._nifToShow;
    }

    public set nifToShow(nifToShow: string) {
        this._nifToShow = nifToShow;
    }

    public statusToBack() {
        if (this.hasVacant) {
            this.status = RankedInscrptionStatus.enum.QUALIFIED;
        } else if (this.hasVacant == null) {
            this.status = RankedInscrptionStatus.enum.UNQUALIFIED;
        } else if (this.reasonExcluded === ReasonChange.enum.REFUSED) {
            this.status = RankedInscrptionStatus.enum.REFUSED;
        } else {
            this.status = RankedInscrptionStatus.enum.REJECTED;
        }
    }

    public statusToFront() {
        if (this.status === RankedInscrptionStatus.enum.QUALIFIED) {
            this.hasVacant = true;
        } else if (this.status === RankedInscrptionStatus.enum.REFUSED || this.status === RankedInscrptionStatus.enum.REJECTED) {
            this.hasVacant = false;
        } else {
            this.hasVacant = null;
        }
        if (this.status === RankedInscrptionStatus.enum.REFUSED) {
            this.reasonExcluded = ReasonChange.enum.REFUSED;
        }
        if (this.status === RankedInscrptionStatus.enum.REJECTED) {
            this.reasonExcluded = ReasonChange.enum.REJECTED;
        }
    }

    public infoToServer() {
        return {
            annotations: this.annotations,
            status: this.status,
            nif: this.nif,
            inscriptionId: this.id
        };
    }
}
