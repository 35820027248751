import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultiLanguage } from '@/Domain/Entities';
import TextAreaContentHelperService from '@/Application/Services/TextAreaContentHelperService';

/**
 * Text area component
 */
@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        $error() {
            return this.$props.required && this.$data.dirty && !this.$props.value;
        },
        length(): number {
            return this.$props.value ? this.$props.value.length : 0;
        }
    }
})
export default class MdTextarea extends Vue {
    @Prop() value: any;
    @Prop() label?: string;
    @Prop() required?: string;
    @Prop() max?: number;
    @Prop() disabled?: boolean;
    /** Implements the translate button */
    @Prop() hasAutoTranslate?: boolean;
    /** Multilanguage object to translate */
    @Prop() contentToTranslate?: MultiLanguage;
    dirty: boolean = false;
    focused: boolean = false;
    textAreaContentHelperService: TextAreaContentHelperService = new TextAreaContentHelperService({ translatedContent: undefined, max: this.max });

    /*
    *
    * @public
    * Fill the input with the translated content
    * @param {$event} - Translated content
    */
    onContentIsTranslated($event) {
        this.textAreaContentHelperService.content = $event;

        const contentToEmit = this.textAreaContentHelperService.isValidTranslatedContentLength() ?
            this.textAreaContentHelperService.content : this.textAreaContentHelperService.contentTruncated;

        this.$emit('input', contentToEmit);
    }

    handleBlur(event: Event) {
        this.dirty = true; 
        this.focused = false;
        this.$props.value = this.$props.value.trim();
        this.$emit('blur', event);
    }
}
