import { SecurityService } from '@/Application/Services/SecurityService';
import axios from 'axios';

export default class AdminEnrolledOnBlockedProcessRouteGuardService {

    public static isAdminDeniedIOnRoute(toRoute: string, ids: string[], params: any): boolean {
        return this._forbiddenRoutes.includes(toRoute) && (ids.includes(params.id) );
    }

    public static async getAdminBlockedAndEnrolledProcessIds(store, mgr: SecurityService): Promise<{ adminBlockedProcessIds: string[] }> {
        const isUserInStore = store.state.userStore.dni;
        if (isUserInStore) {
          return { adminBlockedProcessIds: store.state.userStore.adminBlockedProcessIds || []};
        }
        try {
            const user: any = await mgr.getUser();
            axios.defaults.headers.common.Authorization = 'Bearer ' + user.access_token;
            store.commit('userStore/setDni', user.profile.address);
            if (user.profile.address !== this._emptyAddress) {
                const adminBlockedProcessIds = await store.dispatch('userStore/fetchAdminBlockedProcessesIds', user.profile.address);
                return { adminBlockedProcessIds: adminBlockedProcessIds || [] };
            }
          } catch (error) {
            console.log(error);
        }
        return { adminBlockedProcessIds: [] };
    }

    private static _forbiddenRoutes: string[] = ['CallEdit', 'BagEdit', 'PostProvisionEdit', 'ListManagementEdit', 'InscriptionEdit', 'RatingsEdit', 'ExamsEdit', 'ScalingEdit', 'RankingEdit'];
    private static readonly _emptyAddress = '';
}
