import { CandidateMoveOptionLabel, CandidateMoveOption, Constants } from '@/Domain/enum';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    computed: {
        isMoveButtonDisabled() {
            return (this as MoveReplacementCandidate).isMoveOptionAndOrderUnsetted() 
            || (this as MoveReplacementCandidate).isMoveToOrderAndOrderUnsetted()
            || (this as MoveReplacementCandidate).isOrderOutRange() 
            || (this as MoveReplacementCandidate).isOrderToMoveTheCandidatePosition();
        },
        isMoveToPositionSelected() {
            return (this as MoveReplacementCandidate).moveOptionSelected === CandidateMoveOption.MOVE_TO_ORDER 
            || ((this as MoveReplacementCandidate).moveOptionSelected === CandidateMoveOption.MOVE_TO_END && (this as MoveReplacementCandidate).getSdRadioOptions().length === 1);
        },
        isMoveToEndSelected() {
            return (this as MoveReplacementCandidate).moveOptionSelected === CandidateMoveOption.MOVE_TO_END 
            && (this as MoveReplacementCandidate).getSdRadioOptions().length > 1;
        },
        isMoveOptionDefined() {
            return (this as MoveReplacementCandidate).moveOptionSelected !== null;
        }
    }
})
export default class MoveReplacementCandidate extends Vue {
    @Prop() maxOrder!: number;
    @Prop() minOrder!: number;
    @Prop() candidatePosition!: number;

    isMoveToPositionSelected!: boolean;
    
    CandidateMoveOption = CandidateMoveOption;
    moveOptionSelected: number | null = null;
    orderToMove: number | null = null;
    moveReason: string = '';
    moveReasonLength = Constants.sharedLengths.ONE_THOUSAND;
    showInputError: boolean = false;

    moveCandidate() {
        this.$emit('moveCandidate', 
            {
                moveOption: this.candidatePosition === this.maxOrder ? CandidateMoveOption.MOVE_TO_ORDER : this.moveOptionSelected, 
                reason: this.moveReason, 
                order: this.orderToMove
            }
        );
    }

    closeModal() {
        this.$emit('closeModal');
    }

    isMoveOptionAndOrderUnsetted() {
        return this.moveOptionSelected === null 
        || (this.moveOptionSelected === CandidateMoveOption.MOVE_TO_ORDER 
        && !this.orderToMove);
    }

    isMoveToOrderAndOrderUnsetted() {
        return this.isMoveToPositionSelected && !this.orderToMove;
    }

    isOrderOutRange() {
        return this.orderToMove! && (this.orderToMove! > this.maxOrder
        || this.orderToMove! < this.minOrder);
    }

    isOrderToMoveTheCandidatePosition() {
        return this.orderToMove! === this.candidatePosition;
    }

    isValidOrderInput() {
        if (this.isOrderToMoveTheCandidatePosition()) {
            this.showInputError = true;
            return;
        }
    }

    clearErrors() {
        this.showInputError = false;
    }

    getSdRadioOptions() {
        return this.candidatePosition === this.maxOrder ? [
            {text: CandidateMoveOptionLabel[1], value: 0}
        ] : [
            {text: CandidateMoveOptionLabel[0], value: CandidateMoveOption.MOVE_TO_END},
            {text: CandidateMoveOptionLabel[1], value: CandidateMoveOption.MOVE_TO_ORDER}
        ];
    }

}
